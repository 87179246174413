import { GuestNewsFormType } from '@/app/types/GuestNewsFormType'
import { INITIAL_SELECT_VALUE } from '@/components/ui/select/selectStyle'
import { createSlice } from '@reduxjs/toolkit'

export const initialState: GuestNewsFormType = {
  isFormSubmitted: false,
  newsMode: 'Send a news',
  lastName: '',
  firstName: '',
  honorific: INITIAL_SELECT_VALUE,

  company: '',
  title: '',
  caption: '',
  description: '',
  email: '',

  phone: '',
  phoneCodeId: undefined,

  origin: INITIAL_SELECT_VALUE,

  language: INITIAL_SELECT_VALUE,

  images: [],

  requestCallBack: false,
  preferredTime: '',
}

export const GuestNewsFormSlice = createSlice({
  name: 'guestNewsForm',
  initialState,
  reducers: {
    setGuestNewsForm: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setGuestNewsAbstract: (state, action) => {
      return {
        ...state,
        caption: action.payload,
      }
    },
    setGuestNewsBody: (state, action) => {
      return {
        ...state,
        description: action.payload,
      }
    },
  },
})

export const { setGuestNewsForm, setGuestNewsBody, setGuestNewsAbstract } =
  GuestNewsFormSlice.actions
export default GuestNewsFormSlice.reducer
