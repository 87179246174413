'use client'
import { apiSlice } from '@/app/globalRedux/api/apiSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import protectedRouteReducer from './authorization/protectedRoutes'
import constantsSliceReducer from './dataSlice'
import featureFlagReducer from './featureFlags/featureFlag'
//filter
import commonFilterReducer from './filter/commonFilter'
import companyFilterReducer from './filter/companyFilter'
import eventFilterReducer from './filter/eventFilter'
import newsFilterReducer from './filter/newsFilter'
import productFilterReducer from './filter/productFilter'
import searchReducer from './filter/searchStore'
import companyFilterListReducer from './filterList/companyFilterList'
import productFilterListReducer from './filterList/productFilterList'
import eventMiddleWare from './middleware/eventFilterMiddleWare'
// data
import retailCompanyFilterReducer from './filter/retail/retailCompanyFilter'
import retailNewsFilterReducer from './filter/retail/retailnewsFilter'
import company_profileReducer from './myfructidor/companyProfileStore'
import newsSliceReducer from './myfructidor/newsSlice'
import offersSliceReducer from './myfructidor/offersStore'
import signUpReducer from './signUp/signUp'
import userReducer from './userStore/userStore'
import GuestNewsFormSliceReducer  from './guestNewsForm/GuestNewsFormSlice'

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  signUp: signUpReducer,
  user: userReducer,
  companyProfile: company_profileReducer,
  constantData: constantsSliceReducer,
  newsData: newsSliceReducer,
  offerData: offersSliceReducer,
  productFilter: productFilterReducer,
  newsFilter: newsFilterReducer,
  companyFilter: companyFilterReducer,
  eventFilter: eventFilterReducer,
  searchData: searchReducer,
  companyFilterList: companyFilterListReducer,
  productFilterList: productFilterListReducer,
  featureFlag: featureFlagReducer,
  commonFilter: commonFilterReducer,
  protectedRoutes: protectedRouteReducer,
  retailCompanyFilter: retailCompanyFilterReducer,
  retailNewsFilter: retailNewsFilterReducer,
  GuestNewsForm: GuestNewsFormSliceReducer
})

const reducerProxy = (state: any, action: any) => {
  if (action.type === 'logout/LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(apiSlice.middleware)
      .concat(eventMiddleWare),
  reducer: reducerProxy,
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
